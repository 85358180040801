$(document).ready(function (){
  setTimeout(function (){
    $('#particles-js').addClass('loaded');
    $('.preloader').addClass('hidden');
    $('button, h3, .logo_holder, .navbar').addClass('onscreen');
}, 1500);

  
  
});
